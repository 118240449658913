<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1920 1440"
    style="enable-background: new 0 0 1920 1440"
    xml:space="preserve"
  >
    <g id="Receptionists_xA0_Image_1_">
      <image
        style="overflow: visible"
        width="1920"
        height="1440"
        id="Receptionists_xA0_Image"
        :xlink:href="bg"
      ></image>
    </g>
    <g id="Rectangle_1_1_" class="st0 cursor_pointer" v-if="advertisement.length" >
      <g id="Rectangle_1">
        <g>
          <rect x="262" y="428" class="st1" width="426" height="240" />
        </g>
      </g>
    </g>
    <g id="Layer_2_xA0_Image_1_">
      <a :href="advertisement[0].link">
      <image
        style="overflow: visible"
        width="426"
        height="240"
        id="Layer_2_xA0_Image"
        v-if="advertisement.length"
        :xlink:href="advertisement[0].upload_url"
        transform="matrix(1 0 0 1 262 428)"
        class="cursor_pointer"
      ></image>
      </a>
    </g>
    <g id="Rectangle_1_copy_1_" class="st0 cursor_pointer" v-if="advertisement.length">
      <g id="Rectangle_1_copy">
        <g>
          <rect x="1232" y="428" class="st1" width="426" height="240" />
        </g>
      </g>
    </g>
    <g id="Layer_3_xA0_Image_1_">
      <a :href="advertisement[1].link">
      <image
        style="overflow: visible"
        width="426"
        height="240"
        id="Layer_3_xA0_Image"
        v-if="advertisement.length"
        :xlink:href="advertisement[1].upload_url"
        transform="matrix(1 0 0 1 1232 428)"
         class="cursor_pointer"
      ></image>
      </a>
    </g>
  </svg>
</template>

<script>
import axios from "@/helper/axios";
export default {
  name: "InfoDeskImage",
  data(){
    return {
      advertisement : [],
      bg : process.env.VUE_APP_ASSETS + 'info-desk.png'
    }
  },
  methods : {
  },
  mounted : function(){
    axios
        .get("object/get?page_name=INFO_DESK")
        .then((response) => {
          this.advertisement = response.data.data
        })
        .catch(function (e) {
          console.log(e);
        });
  }
};
</script>

<style>
</style>