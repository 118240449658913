<template>
  <v-container
    class="pa-0 mt-1 d-flex align-start"
    :style="{ maxWidth: maxWidth + 'px' }"
  >
    <v-card class="rounded-lg" width="100%">
      <v-card-title class="primary white--text pa-1">
        <v-btn icon dark to="/">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="mr-2">Info Desk</span>
      </v-card-title>
      <v-card-text class="white rounded-lg card-relative pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <DeskImages />
            <v-card-title
            style="position:absolute;top:44px;width:100%" class="white--text green darken-4"
                >{{$t('info_desk.message')}}
                <v-btn
                  @click="openInfoChat"
                  class="ml-2"
                  rounded
                  depressed
                  color="primary darken-2"
                  >{{$t('info_desk.start_chat_btn')}}
                  <v-icon right>mdi-message-processing-outline</v-icon></v-btn
                >
              </v-card-title>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState,mapMutations } from "vuex";
import DeskImages from './DeskImage'
export default {
  name: "Desk",
  components : {
    DeskImages
  },
  data() {
    return {
      chat_url: process.env.VUE_APP_PUBLIC_PATH + "/#/info-desk-chat",
    };
  },
  computed: {
    ...mapState("utils", ["maxWidth"]),
  },
  methods: {
    ...mapMutations("attendees", ["setData"]),
    openLink(link){
      if(link.hasPopup){
        this.setData({
          key : 'show_modal',
          data : true
        })
      } else if(link.hasLink){
        this.$helper.openUrl(link.link)
      }else {
        this.$router.push({path : link.to})
      }
    },
    openInfoChat() {
      window.open(this.chat_url,"info-desk-chat","location=yes,height=500,width=350,scrollbars=yes,status=yes");
    },
  },
};
</script>

<style></style>
